.tourTimeLabel {
    font-size: 13px;
    color: #666;
    margin-top: 6px;
    margin-bottom: 6px;
}
.tourWayBadge {
    background-color: #1777ff29;
    color: #1677ff;
    font-weight: 600;
    font-size: 12px;
}
.travelTimeLabel {
    color: #666;
    margin-top: 6px;
    margin-bottom: 6px;
}
.showPriceDetailsCollapse {
    width: 100%;
}
.showPriceDetailsCollapse .ant-collapse-content-box {
    padding: 0 !important;
}
.showPriceDetailsCollapse .ant-collapse-header {
    padding-inline: 0 !important;
}