.HakkimizdaBanner {
  position: relative;
  justify-content: space-evenly;
  align-items: center;
  padding: 60px 0px;
  padding-inline: 125px;
  max-width: 100%;
  margin: 0 auto;
  background-color: #06213d;
}

.Hakkimizdacontact-section-heading {
  font-family: "Poppins", sans-serif;
  color: #fff;
}

.MetinContainerHakkimizda {
  align-items: center;
  padding-inline: 125px;
  /* height: 350px; */
  height: 100%;
  margin: 0 auto;
  border-radius: 15px;
  margin-top: 50px;
}

@media (max-width: 767px) {
  .HakkimizdaBanner {
    width: 100%;
    height: 65px;
    padding-inline: 40px;
  }

  .Hakkimizdacontact-section-heading {
    margin-top: 0px;
    width: 95%;
    font-size: 18px;
    margin-left: auto;
    margin-right: auto;
  }
  .MetinContainerHakkimizda {
    padding-inline: 40px;
    height: 100%;
  }
}
.MetinContainerHakkimizda h1 {
  margin-top: 50px;
  font-size: 24px;
  /* float: left; */
  font-family: "Poppins", sans-serif;
}

.MetinContainerHakkimizda p {
  /* font-size: 16px;
  float: left;
  font-family: "Poppins", sans-serif;
  font-weight: normal; */
}

@media (max-width: 767px) {
  .MetinContainerHakkimizda h1 {
    font-size: 20px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
}
