.img-drop-container {
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
}

.img-drop-card {
  border-radius: 10px;
  width: 600px;
  height: auto;
  background-color: #ffffff;
  padding: 10px 30px 10px;
}

.img-drop-card h3 {
  font-size: 22px;
  font-weight: 600;
}

.drop_box {
  margin: 10px 0;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 2px dotted #a3a3a3;
  border-radius: 5px;
}

.drop_box h4 {
  font-size: 16px;
  font-weight: 400;
  color: #2e2e2e;
}

.drop_box p {
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 12px;
  color: #2e2e2e;
}

.btn {
  width: 200px;
  background-color: #114af3;
  color: #fff;
  line-height: 0px;
  border: none;
}

.btn:hover {
  transition: 0.5s;
  background-color: #054ab9;
  box-shadow: 0 8px 24px 0 rgba(16, 39, 112, 0.2);
}
.form input {
  margin: 10px 0;
  width: 100%;
  background-color: #e2e2e2;
  border: none;
  outline: none;
  padding: 12px 20px;
  border-radius: 4px;
}

@media screen and (max-width: 767px) {
    .img-drop-card {
        padding: 0px 0px 10px 0px;
    }
}