.texteditor-container {
    margin-top: 50px;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
}

.rdw-editor-toolbar {
    margin-top: 20px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    border-radius: 10px;
}

.DraftEditor-editorContainer {
    margin-top: 10px;
    border-radius: 5px;
    border: 1px solid #cdcfd3;
    background-color: #e6e7e9;
    max-height: 320px;
}
.rdw-option-wrapper {
    padding: 12px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
}
.rdw-option-wrapper:hover {
    transition: 0.5s;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

@media (min-width: 767px) and (max-width: 1800px) {
    .texteditor-container {
        width: 95%;
    }
}

@media (max-width: 767px) {
    .texteditor-container {
        width: 95%;
    }
}