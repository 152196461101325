.passenger-input-box {
  position: relative;
  height: 56px;
  max-width: 900px;
  width: 100%;
  background: #f1f1f1;
  margin: 0 20px;
  border-radius: 8px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5%;
  border: 1px solid #cdcfd3;
}

.passenger-input-box .button {
  position: absolute;
  top: 50%;
  width: 115px;
  transform: translateY(-50%);
}

.passenger-input-box input {
  height: 100%;
  width: 100%;
  outline: none;
  font-size: 18px;
  font-weight: 400;
  border: none;
  padding: 0 155px 0 65px;
  background-color: transparent;
}

.passenger-input-box input::placeholder {
  font-family: "Poppins", sans-serif;
}

.passenger-input-box input::selection {
  background-color: #d6f5ff;
}
.passenger-input-box input:focus {
    transition: 0.5s;
    border: 1px solid #06213d;
}

.passenger-input-box .button {
  right: 20px;
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  border: none;
  padding: 12px 30px;
  height: 40px;
  border-radius: 10px;
  background-color: #06213d;
  cursor: pointer;
}

.passenger-input-box .button:hover {
  background-color: #4a38d4;
  transition: 0.5s;
  box-shadow: 0 8px 24px 0 rgba(16, 39, 112, 0.2);
}

.passenger-input-box .button:active {
  transform: translateY(-50%) scale(0.98);
}

.SearchIcon {
  position: absolute;
  justify-content: center;
  font-size: 18px;
  left: 20px;
}

@media screen and (max-width: 767px) {
  .passenger-input-box {
    height: 55px;
    margin: 0 8px;
    width: 95%;
  }
  .passenger-input-box input {
    padding: 0 112px 0 50px;
  }
  .passenger-input-box .button {
    right: 12px;
    font-size: 14px;
    padding: 8px 18px;
    height: 40px;
    width: 80px;
    border-radius: 5px;
  }
}

.passenger-group {
  margin-left: auto;
  margin-right: auto;
  height: 200px;
  overflow-x: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  border: 3px dotted #e6e7e9;
  border-radius: 10px;
  padding-left: 20px;
}

/* .passenger-group::-webkit-scrollbar {
    width: 5px;
    background-color: transparent;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
    background-color: transparent;
    padding: 5px !important;

}

::-webkit-scrollbar-thumb {
    background-color: #06213d;
    border: none;
    border-radius: 3px !important;
} */

.passenger-group li {
  background-color: #f1f1f1;
  border: 1px solid #cdcfd3;
  cursor: pointer;
  padding: 15px;
  margin-right: 20px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
}

.passenger-group li:hover {
  transition: 0.5s;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
}

.passenger-group-icon {
    font-size: 24px;
  background-color: #06213d;
  color: #FFF;
  border-radius: 50%;
}
