.blogadd-banner {
  position: relative;
  justify-content: space-evenly;
  align-items: center;
  padding: 60px 0px;
  top: 10px;
  height: 80px;
  max-width: 100%;
  margin: 0 auto;
  background-color: #06213d;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  width: 95%;
  border-radius: 10px;
}

.blogadd-banner h1 {
  font-family: "Poppins", sans-serif;
  margin-top: 0px;
  margin-bottom: 0px;
  line-height: 0px;
  position: absolute;
  text-align: left;
  font-size: 38px;
  margin-left: 218px;
  color: #fff;
}

@media (max-width: 767px) {
  .blogadd-banner {
    height: 45px;
  }
  .blogadd-banner h1 {
    text-align: center;
    font-size: 24px;
    margin-left: 20px;
  }
}

.upload-blog-btn {
  line-height: 0px;
  margin-top: 50px;
  width: 200px;
  background-color: #114af3;
  color: #fff;
  border: none;
  margin-left: auto;
  margin-right: auto;
}

.upload-blog-btn:hover {
  transition: 0.5s;
  background-color: #054ab9;
  box-shadow: 0 8px 24px 0 rgba(16, 39, 112, 0.2);
}

.img-upload-container {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

#imageContainer img {
  background-color: red;
  max-height: 300px;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
}

@media (max-width: 767px) {
  .upload-blog-btn {
    width: 150px;
  }
  .img-upload-container {
    width: 200px;
  }
  #imageContainer img {
    max-width: 250px;
    float: left;
  }
}