@import url('https://fonts.cdnfonts.com/css/sf-pro-display');
@import url('https://fonts.cdnfonts.com/css/nouvel-r');

body {
    background-color: #fff;
}
.testBtn {
    background-color: red;
}

.home-header-section {
    /* background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("https://images.unsplash.com/photo-1535919020263-f79f5313f336?q=80&w=2938&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"); */
    background-size: cover;
    background-position: center;
    background-color: #06213d;
    padding-top: 100px;
    padding-bottom: 50px;
}
.home-header-title {
    /* margin-top: 20px; */
    padding-inline: 125px;
}

@media screen and (max-width: 1125px) {
    .home-header-title {
        padding-inline: 100px;
    }
  }
  @media screen and (max-width: 1030px) {
    .home-header-title {
        padding-inline: 75px;
    }
  }
  @media screen and (max-width: 975px) {
    .home-header-title {
        padding-inline: 50px;
    }
  }
  @media screen and (max-width: 930px) {
    .home-header-title {
        padding-inline: 25px;
    }
  }
  @media screen and (max-width: 880px) {
    .form-select-ticket .search-form {
      margin-left: 0px !important;
      margin-right: 0px !important;
    }
    .home-header-title {
        padding-inline: 25px;
    }
  }
  @media screen and (max-width: 600px) {
    .home-header-section {
        padding-top: 64px;
    }
    .home-header-title {
        padding-inline: 25px;
        margin-top: 25px;
    }

    .home-header-title h2 {
        text-align: center;
    }
  }