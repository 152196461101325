.pnr-inquiry-banner {
    position: relative;
    top: 60px;
    justify-content: space-evenly;
    align-items: center;
    padding: 60px 0px;
    height: 200px;
    max-width: 100%;
    margin: 0 auto;
    background-color: #06213d;
}

@media (max-width: 767px) {
    .pnr-inquiry-banner {
        height: 65px;
    }
    .pnr-section {
      position: inherit !important;
    }
}

.pnr-section {
  /* display: block;
  margin-left: auto;
  margin-right: auto;*/
  width: 100%;
  position: absolute;
}

.pnr-banner-title {
  font-size: 40px;
  font-family: "Poppins", sans-serif;
  color: #fff;
}

.pnr-banner-subtitle {
    font-family: "Poppins", sans-serif;
    color: #d4d4d4;
    font-size: 16px;
    width: 1170px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

@media (max-width: 767px) {
    .pnr-section {
        height: 265px;
    }
    .pnr-banner-title {
      margin-top: -35px;
      font-size: 1.375rem;
      line-height: 1.36;
      margin-bottom: 8px;
    }
    .pnr-banner-subtitle {
      width: 80%;
      margin-left: 35px;
      margin-right: 35px;
      text-align: center;
    }
}

@media (max-width: 1466px) {
  .pnr-banner-subtitle {
    width: 80%;
    font-size: 14px;
  }
}

.ticket-informations {
  margin-bottom: 32px;
}

.ticket-informations .ticket-info-item {
 font-size: 16px;
 color: #333;
 line-height: 24px;
}