@import url('https://fonts.cdnfonts.com/css/inter');

:root {
  --padding-desktop: 125px;
  --padding-mobile: 20px;
  --padding-1125: 100px;
  --padding-1030: 75px;
  --padding-975: 50px;
  --padding-930: 25px;
  --padding-880: 25px;
}

.Navbar .nav-logo-link {
  margin-left: var(--padding-desktop);
} 
.Navbar .nav-items {
  margin-right: var(--padding-desktop);
}

@media (max-width: 1125px) {
  .Navbar .nav-logo-link {
    margin-left: var(--padding-1125);
  }
  .Navbar .nav-items {
    margin-right: var(--padding-1125);
  }
}
@media (max-width: 1030px) {
  .Navbar .nav-logo-link {
    margin-left: var(--padding-1030);
  }
  .Navbar .nav-items {
    margin-right: var(--padding-1030);
  }
}
@media (max-width: 975px) {
  .Navbar .nav-logo-link {
    margin-left: var(--padding-975);
  }
  .Navbar .nav-items {
    margin-right: var(--padding-975);
  }
}
@media (max-width: 930px) {
  .Navbar .nav-logo-link {
    margin-left: var(--padding-930);
  }
  .Navbar .nav-items {
    margin-right: var(--padding-930);
  }
}
@media (max-width: 880px) {
  .Navbar .nav-logo-link {
    margin-left: var(--padding-880);
  }
  .Navbar .nav-items {
    margin-right: var(--padding-880);
  }
}
@media (max-width: 767px) {
  .Navbar .nav-logo-link {
    margin-left: var(--padding-mobile);
  }
  .Navbar .nav-items {
    margin-right: var(--padding-mobile);
  }
}  

.Navbar {
  height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  /*-webkit-box-shadow: 0 1px 2px 0 rgba(0,0,0,.16);
  box-shadow: 0 1px 2px #00000029;*/
  position: fixed;
  top: 0;
  z-index: 1000;
  width: 100%;
  user-select: none;
}

.Navbar .nav-logo img {
  width: 150px;
  height: auto;
}

.Navbar .nav-items .navbar-icon {
  max-width: 17px;
  max-height: 17px;
  margin-right: 7px;
  float: left;
  display: flex;
  position: absolute;
  top: 25%;
}

.Navbar .nav-items a {
  color: #1c2b3d;
  font-size: 16px;
  text-decoration: none;
  margin: 15px;
  position: relative;
  opacity: 0.9;
  font-weight: 500;
  white-space: nowrap;
  padding: 8px 10px 8px 10px;
  border-radius: 5px;
  line-height: 21px;
  letter-spacing: 0px;
  font-family: 'Poppins', sans-serif;
}


.Navbar .nav-items a:hover {
  opacity: 1;
  color: #1c2b3d;
  background-color: #F5F7F9;
}

.Navbar .nav-items a:hover::before {
  width: 100%;
}

.Navbar .nav-items button {
  border: 2px solid #24b4fb;
  background-color: #24b4fb;
  border-radius: 0.9em;
  padding: 0.8em 1.2em 0.8em 1em;
  transition: all ease-in-out 0.2s;
  font-size: 15px;
}

.Navbar .nav-items button span {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: 600;
}

.Navbar .nav-items button:hover {
  background-color: #0071e2;
}

.Navbar .nav-toggle {
  display: none;
}

@media (max-width: 700px) {
  .Navbar {
    height: 60px;
  }

  .Navbar .nav-items {
    position: absolute;
    top: 61.4px;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    background: #fff;
    left: 0;
    width: 100%;
    transform: translateX(-100%);
    transition: all .45s;
    z-index: 100;
  }

  .Navbar .nav-items a {
    border: 1px solid #E8E9F1;
    color: #1c2b3d;
    margin-top: 0px;
    margin-left: 15px;
    margin-right: 15px;
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    border-radius: 10px;
  }

  .Navbar .nav-items button {
    margin: 0 0 15px 8px;
    width: 30%;
  }

  .Navbar .nav-items .navbar-icon {
    position: relative;
  }

  .Navbar .nav-items a::before {
    background: transparent;
  }

  .navbar {
    background-color: transparent;
  }

  .Navbar .nav-items.switch {
    transform: translateX(0);
  }

  .Navbar .nav-toggle {
    display: flex;
    width: 40px;
    height: 40px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: 180px;
    border-radius: 6px;
    box-shadow: rgba(28, 43, 61, 24%)0px 1px 2px 0px;
    margin-right: 20px;
  }

  .nav-toggle .hamburger {
    position: relative;
    width: 18px;
    height: 3px;
    background: #1c2b3d;
    transition: all 0.45s ease-in-out;
  }

  .nav-toggle .hamburger::before,
  .nav-toggle .hamburger::after {
    content: "";
    position: absolute;
    height: 3px;
    background: #1c2b3d;
    border-radius: 2px;
    transition: all 0.45s ease-in-out;
  }

  .nav-toggle .hamburger::before {
    width: 18px;
    transform: translateY(-8px);
    right: 0;
  }

  .nav-toggle .hamburger::after {
    width: 18px;
    transform: translateY(8px);
  }

  .nav-toggle.switch .hamburger {
    transform: translateX(-40px);
    background: transparent;
  }

  .nav-toggle.switch .hamburger::before {
    width: 18px;
    transform: rotate(45deg) translate(26px, -26px);
  }

  .nav-toggle.switch .hamburger::after {
    transform: rotate(-45deg) translate(26px, 26px);
  }
}