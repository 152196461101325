
.reservation-steps-container {
    padding-top: 50px
}
.continue-container {
    border-radius: 8px;
    /* position: sticky; */
    top: 56px;
    z-index: 900 !important;
    background-color: white;
    padding: 25px;
    /* margin-right: 24px; */
    margin-top: 24px;
    /* height: 100%; */
    display: flex;
    -webkit-box-shadow: 0px -1px 8px 3px rgb(193 193 193 / 13%);
    -moz-box-shadow: 0px -1px 8px 3px rgb(193 193 193 / 13%);
    box-shadow: 0px -1px 8px 3px rgb(193 193 193 / 13%);
}

.summary-buttons {
    font-weight: 600;
}
.summary-section {
    height: 100%;
    width: 100%;
}
.summary-title {
    margin:0 !important;
}
.summary-section-row {
    height: 100%;
}
.summary-totals {
    display: flex;
    justify-content: space-between;
}

.summary-totals span {
    font-size: 16px !important;
}
.summary-total-price {
    font-weight: 600;
}