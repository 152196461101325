footer{
    position: relative;
    background: #06213d;
    width: 100%;
    bottom: 0;
    left: 0;
    font-family: "Poppins", sans-serif;
    border-top: 1px solid #E8E9F1;
  }
  footer .content-footer{
    max-width: 1250px;
    margin: auto;
    padding: 30px 40px 40px 40px;
  }
  footer .content-footer .top{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 50px;
  }
  .content-footer .top .logo-details{
    color: #484848;
    font-size: 30px;
  }
  .content-footer .top .media-icons{
    display: flex;
    cursor: pointer;
  }
  .content-footer .top .media-icons a{
    height: 40px;
    width: 40px;
    margin: 0 8px;
    border-radius: 50%;
    text-align: center;
    line-height: 40px;
    color: #fff;
    font-size: 17px;
    text-decoration: none;
    transition: all 0.4s ease;
  }
  .top .media-icons a:nth-child(1){
    background: transparent;
  }
  .top .media-icons a:nth-child(2){
    background: transparent;
  }
  .top .media-icons a:nth-child(3){
    background: #E1306C;
  }
  .top .media-icons a:nth-child(3):hover{
    color: #E1306C;
    background: #fff;
  }
  .top .media-icons a:nth-child(4){
    background: #0077B5;
  }
  .top .media-icons a:nth-child(4):hover{
    color: #0077B5;
    background: #fff;
  }
  .top .media-icons a:nth-child(5){
    background: #FF0000;
  }
  .top .media-icons a:nth-child(5):hover{
    color: #FF0000;
    background: #fff;
  }
  footer .content-footer .link-boxes{
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  footer .content-footer .link-boxes .box{
    width: calc(100% / 5 - 10px);
  }
  .content-footer .link-boxes .box .link_name{
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
    position: relative;
  }
  .link-boxes .box .link_name::before{
    content: '';
    position: absolute;
    left: 0;
    bottom: -5px;
    height: 2px;
    width: 35px;
    background: #fff;
  }
  .content-footer .link-boxes .box li{
    margin: 6px 0;
    list-style: none;
  }
  .content-footer .link-boxes .box li a{
    color: #dedede;
    font-size: 14px;
    font-weight: 400;
    text-decoration: none;
    opacity: 0.8;
    transition: all 0.4s ease
  }
  .content-footer .link-boxes .box li a:hover {
    color: #fff;
  }
  .content-footer .link-boxes .input-box-footer{
    margin-right: 55px;
  }
  footer .bottom-details{
    width: 100%;
    background: #F8F8FD;
  }
  footer .bottom-details .bottom_text{
    padding: 20px 40px;
    display: flex;
    justify-content: space-between;
  }
  .bottom-details .bottom_text span,
  .bottom-details .bottom_text a{
    font-size: 14px;
    font-weight: 300;
    color: #484848;
    opacity: 0.8;
    text-decoration: none;
  }
  .bottom-details .bottom_text a:hover{
    opacity: 1;
    text-decoration: underline;
  }
  .bottom-details .bottom_text a{
    margin-right: 10px;
  }
  .footer {
    background-color: #fff;
    padding: 25px
  }
  .footer span {
    color: #777;
  }
  .footerText {
    height: 100%;
    align-items: center;
    display: flex;
  }
  .footerImages {
    display: flex;
    justify-content: right;
    align-items: center;
    height: 100%;
  }
  @media (max-width: 900px) {
    footer .content-footer .link-boxes{
      flex-wrap: wrap;
    }
    footer .content-footer .link-boxes .input-box-footer{
      width: 40%;
      margin-top: 10px;
    }
  }
  @media (max-width: 768px){
    .footerText {
      width: 100%;
    }
    .footerText span{
      text-align: center;
      width: 100%;
    }
    .footerImages {
      padding-right: 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
    footer{
      position: relative;
    }
    .content-footer .top .logo-details{
      font-size: 26px;
    }
    .content-footer .top .media-icons a{
      height: 35px;
      width: 35px;
      font-size: 14px;
      line-height: 35px;
    }
    footer .content-footer .link-boxes .box{
      width: calc(100% / 3 - 10px);
    }
    footer .content-footer .link-boxes .input-box-footer{
      width: 60%;
    }
    .bottom-details .bottom_text span,
    .bottom-details .bottom_text a{
      font-size: 12px;
    }
  }
  @media (max-width: 520px){
    footer::before{
      top: 145px;
    }
    footer .content-footer .top{
      flex-direction: column;
    }
    .content-footer .top .media-icons{
      margin-top: 16px;
    }
    footer .content-footer .link-boxes .box{
      width: calc(100% / 2 - 10px);
      margin-left: -50px;
    }
    footer .content-footer .link-boxes .input-box-footer{
      width: 100%;
    }
}