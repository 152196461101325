.header-section {
    /* background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("https://images.unsplash.com/photo-1535919020263-f79f5313f336?q=80&w=2938&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"); */
    background-size: cover;
    background-position: center;
    background-color: #06213d;
    padding-top: 100px;
    padding-bottom: 50px;
}
.header-title {
    margin-top: 50px;
    /* padding-inline: 150px; */
}

@media (max-width: 767px) {
    /* .header-title, .header-section {
        padding-inline: 20px;
    } */
    .header-title h2 {
        text-align: center;
    }
    .header-title {
        margin-top: 0px;
        margin-bottom: 15px;
    }
    .header-title.search {
        display: none;
    }
    .header-section.search {
        padding-top: 65px !important;
    }
}
