.show-payment-oky-btn {
  line-height: 0px;
  margin-top: 10px;
  width: 200px;
  background-color: #114af3;
  color: #fff;
  border: none;
  margin-left: auto;
  margin-right: auto;
}

.show-payment-oky-btn:hover {
  transition: 0.5s;
  background-color: #054ab9;
  box-shadow: 0 8px 24px 0 rgba(16, 39, 112, 0.2);
}
