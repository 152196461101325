@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap');

/**{
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}
body{
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 40px;
  background: linear-gradient(115deg, #56d8e4 10%, #9f01ea 90%);
}*/

.section-main-contact {
  padding-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 70vh;
  background-color: #F2F6F8;
  border-top-left-radius: 50% 75px;
  border-top-right-radius: 50% 75px;
  padding-inline: 125px;
}

.container {
  max-width: 800px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
  background: #fff;
  width: 800px;
  padding: 25px 40px 10px 40px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
}

.container .text {
  text-align: center;
  font-size: 1.5rem;
  color: #20415B;
  font-family: 'Poppins', sans-serif;
}

.container .textKucuk {
  font-family: 'Poppins', sans-serif;
  text-align: center;
  color: #555555;
}

.dividerForm {
  width: 100%;
  height: 1px;
  background-color: #ccc;
  margin: 20px 0;
}

.container form {
  padding: 30px 0 0 0;
}

.container form .form-row {
  display: flex;
  margin: 32px 0;
  ;
}

form .form-row .input-data {
  width: 100%;
  height: 40px;
  margin: 0 20px;
  position: relative;
  border-radius: 5px;
  font-family: 'Poppins', sans-serif;
}

form .form-row .textarea {
  height: 70px;
}

.input-data input,
.textarea textarea {
  display: block;
  width: 100%;
  height: 100%;
  border: none;
  font-size: 17px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  font-family: 'Poppins', sans-serif;
}

.input-data input:focus~label,
.textarea textarea:focus~label,
.input-data input:valid~label,
.textarea textarea:valid~label {
  transform: translateY(-20px);
  font-size: 16px;
  color: #2B3991;
  font-family: 'Poppins', sans-serif;
}

.textarea textarea {
  resize: none;
  padding-top: 10px;
}

.input-data label {
  position: absolute;
  pointer-events: none;
  bottom: 10px;
  margin-left: 15px;
  font-size: 14px;
  transition: all 0.3s ease;
}

.textarea label {
  width: 100%;
  bottom: 40px;
  background: #fff;
}

.input-data .underline {
  position: absolute;
  bottom: 0;
  height: 2px;
  width: 100%;
}

.input-data .underline:before {
  position: absolute;
  content: "";
  height: 2px;
  width: 100%;
  border-bottom: 2px solid #2B3991;
  transform: scaleX(0);
  transform-origin: center;
  transition: transform 0.3s ease;
}

.input-data input:focus~.underline:before,
.input-data input:valid~.underline:before,
.textarea textarea:focus~.underline:before,
.textarea textarea:valid~.underline:before {
  transform: scale(1);
}

.submit-btn .input-data {
  overflow: hidden;
  height: 45px !important;
  width: 25% !important;
}

.contact-form-btn {
  right: 20px;
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  border: none;
  padding: 12px 30px;
  border-radius: 10px;
  background-color: #114af3;
  cursor: pointer;
}

.contact-form-btn:hover {
  background-color: #054ab9;
  transition: 0.5s;
  box-shadow: 0 8px 24px 0 rgba(16,39,112,.2);
}


.submit-btn .input-data input {
  background: none;
  border: none;
  color: #fff;
  font-size: 17px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  position: relative;
  z-index: 2;
}

@media (max-width: 767px) {
  .section-main-contact {
    padding: 15px;
    margin-top: 25px;
    border-top-left-radius: 0%;
    border-top-right-radius: 0%;
    padding-inline: 40px;
  }

  .container .text {
    font-size: 25px;
  }

  .container form {
    padding: 10px 0 0 0;
  }

  .container form .form-row {
    display: block;
  }

  form .form-row .input-data {
    margin: 35px 0 !important;
  }

  .submit-btn .input-data {
    width: 40% !important;
  }
}