.w-100 {
    width: 100%;
}

body {
    font-family: 'Poppins', 'Arial' !important;
}

h1,h2,h2,h3,h4,p,span,label {
    font-family: 'Poppins';
}