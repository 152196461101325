.table-container-tours {
  margin-top: 250px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.table-top-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 1280px;
  margin-left: auto;
  margin-right: auto;
  height: auto;
}

.table-top-container .select {
  width: auto;
  align-self: flex-start;
  background-color: #fff;
  border: 1px solid #e8e9f1;
}
.table-top-container .select:hover {
  transition: 0.5s;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.table-top-container .tour-add-btn {
  align-self: flex-end;
  width: 100px;
  background-color: #114af3;
  color: #fff;
  line-height: 0px;
  border: none;
}
.table-top-container .tour-add-btn:hover {
  transition: 0.5s;
  background-color: #054ab9;
  box-shadow: 0 8px 24px 0 rgba(16, 39, 112, 0.2);
}

.ui-table {
  width: 1280px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  border-collapse: separate;
  border: 1px solid #e8e9f1;
  background-color: #fff;
  border-radius: 5px;
  overflow-x: auto;
  font-family: "Poppins", sans-serif;
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px,
    rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
}

th::selection {
  background-color: #fdc33c;
}
td::selection {
  background-color: #fdc33c;
}

.ui-table th,
.ui-table td {
  padding: 8px;
  text-align: center;
}

.ui-table th:not(:last-child) {
  border-right: 1px solid #dddddd;
}

.ui-table tr:not(:last-child) td {
  border-bottom: 1px solid #dddddd;
}

.ui-table button {
  padding: 6px 10px;
  width: 50px;
  height: 50px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.ui-table button:hover {
  background-color: #45a049;
}

.ui-table .button-container {
  width: auto;
  display: flex;
  justify-content: center;
  gap: 5px;
}

#show-icon {
  background-color: #45a049;
}
#show-icon:hover {
  background-color: #387e3b;
}
#edit-icon {
  background-color: #0b3b85;
}
#edit-icon:hover {
  background-color: #092b5f;
}
#delete-icon {
  background-color: #d92a2e;
}
#delete-icon:hover {
  background-color: #a71e20;
}

.table-icon {
  font-size: 20px;
}

@media only screen and (max-width: 767px) {
  .table-container-tours {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    overflow-x: auto;
  }
  .table-top-container {
    width: 100%;
  }
  .ui-table {
    border-collapse: collapse;
    overflow-x: auto;
    border-radius: 15px;
  }
  .ui-table th,
  .ui-table td {
    font-size: 12px;
  }
  .ui-table button {
    width: 50px;
  }
}

@media (min-width: 767px) and (max-width: 1600px) {
  .ui-table {
    width: 100%;
  }
  .table-top-container {
    width: 100%;
  }
}

.tour-add {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

.tour-add-modal-content {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  text-align: center;
  background-color: #fefefe;
  margin: 5% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 600px;
  border-radius: 10px;
}
.company-select-modal {
  width: 95%;
  margin-left: auto;
  margin-right: auto;
}
.price-container-modal {
  width: 95%;
  margin-left: auto;
  margin-right: auto;
}
.price-container-modal input {
  cursor: text;
}
.add-trip-btn {
  width: 100%;
  background-color: #114af3;
  color: #fff;
  line-height: 0px;
  border: none;
}
.add-trip-btn:hover {
  transition: 0.5s;
  background-color: #054ab9;
  box-shadow: 0 8px 24px 0 rgba(16, 39, 112, 0.2);
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
@media screen and (max-width: 767px) {
  .tour-add-modal-content {
    width: 80%;
  }
}

.show-modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

.show-modal-content {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  text-align: center;
  background-color: #fefefe;
  margin: 5% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 600px;
  border-radius: 10px;
}
.show-modal-content .modal-sub-title {
  display: flex;
  width: 100%;
  text-align: left;
  background-color: #fdc33c;
  border-radius: 5px;
  margin-bottom: 20px;
}
.show-modal-content .modal-sub-title h1 {
  margin-left: 10px;
  margin-right: 30px;
  line-height: 0px;
  font-size: 24px;
}
.round-trip-cont {
  display: flex;
  margin-bottom: 5px;
  border: 1px solid #e8e9f1;
  border-radius: 5px;
}
.round-trip-item {
  display: flex;
  margin-left: 10px;
  flex: 1;
  font-size: 16px;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
@media screen and (max-width: 767px) {
  .show-modal-content {
    width: 80%;
  }
  .round-trip-cont {
    display: block;
  }
}

.update-modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
}
.update-modal-content {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  text-align: center;
  background-color: #fefefe;
  margin: 5% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 600px;
  border-radius: 10px;
}
.update-modal-location {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}
.update-modal-time {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.update-modal-time input {
  /* margin-right: 10px; */
  width: 275px;
}
.update-modal-btn {
  background-color: #0b3b85;
  line-height: 0px;
  color: #fff;
  border: none;
  margin-top: 15px;
}
.update-modal-btn:hover {
  transition: 0.5s;
  background-color: #092b5f;
  box-shadow: 0 8px 24px 0 rgba(16, 39, 112, 0.2);
}
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  transition: 0.5s;
  color: black;
  text-decoration: none;
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .update-modal-content {
    width: 80%;
  }
  .update-modal-location {
    display: block;
  }
}

.delete-modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
}
.delete-modal-content {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  text-align: center;
  background-color: #fefefe;
  margin: 5% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 600px;
  border-radius: 10px;
}
.delete-modal-button-container {
  display: flex;
}
.delete-modal-btn {
  line-height: 0px;
  margin-right: 10px;
}
#vazgec-btn {
  background-color: #fff;
}
#vazgec-btn:hover {
  transition: 0.5s;
  box-shadow: 0 8px 24px 0 rgba(16, 39, 112, 0.2);
}
#sil-btn {
  background-color: #d92a2e;
  color: #fff;
  border: none;
}
#sil-btn:hover {
  transition: 0.5s;
  background-color: #a71e20;
  box-shadow: 0 8px 24px 0 rgba(16, 39, 112, 0.2);
}
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  transition: 0.5s;
  color: black;
  text-decoration: none;
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .delete-modal-content {
    width: 80%;
  }
}
