.pnr-container {
  display: flex;
  align-items: center;
  background-color: #fff;
  /* width: 100%; */
  /* height: 100px; */
  margin-left:125px;
  margin-right: 125px;
  padding-left: 20px;
  padding: 20px;
  padding-right: 20px;
  border-radius: 10px;
  /* margin-top: 90px; */
  box-shadow: 0 12px 24px -4px rgba(0,0,0,.06),0 8px 16px 0 rgba(0,0,0,.12);
  margin-top: 10px;
}

.pnr-container input {
  margin-right: 10px;
  background-color: #f0f0f0;
  border: 1px solid #E6E7E9;
  border-radius: 8px;
}

.pnr-container input:focus {
  transition: 0.5s;
    border: 1px solid;
    border-color: #114af3;
}

.pnr-container .pnr-sorgula-btn {
  background-color: #114af3;
  color: #fff;
  border: none;
  width: 430px;
  height: 56px;
}

.pnr-container .pnr-sorgula-btn span {
  font-size: 16px;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
}

.pnr-container .pnr-sorgula-btn:hover {
  transition: 0.5s;
  background-color: #114af3;
}

@media (max-width: 767px) {
  .pnr-container {
    /* margin-top: 75px; */
    /* padding-top: 20px; */
    display: block;
    width: auto;
    height: auto;
    margin-inline: 20px;
  }

  .pnr-container input {
    margin-bottom: 25px;
  }

  .pnr-container .pnr-sorgula-btn {
    margin-left: 0px;
    width: 100%;
    margin-bottom: 25px;
  }
}

@media (max-width: 1466px) {
  .pnr-container .pnr-sorgula-btn span{
    font-size: 14px;
  }
}

.modalPnr {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  font-family: "Poppins", sans-serif;
}

.modal-content-pnr {
  background-color: #fefefe;
  margin: 5% auto;
  padding: 20px;
  border: none;
  max-width: 600px;
  width: 80%;
  border-radius: 10px;
  font-weight: 500;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.errorModal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.errorModal-content {
  background-color: #fefefe;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
}

.errorModal-content .close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.errorModal-content .close:hover,
.errorModal-content .close:focus {
  transition: 0.5s;
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.errorModalBaslik {
  color: #090f24;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  margin-bottom: 0px;
  line-height: 1.4;
}

.errorModalAciklama {
  color: #525765;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  text-align: center;
  margin-bottom: 25px;
}

.errorModalButon {
  background-color: #345DEE;
  color: #fff;
  font-family: "Poppins", sans-serif;
  height: 45px;
  width: 75px;
  border: none;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.errorModalButon:hover {
  transition: 0.5s;
  background-color: #2341aa;
}

@media (max-width: 767px) {
  .errorModal-content {
    width: 75%;
  }
}