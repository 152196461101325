@import url("https://fonts.cdnfonts.com/css/poppins");

.search-widget-form-item.passengers .ant-form-item-control {
  height: 56px;
  background-color:white !important;
  justify-content: center;
  align-items: center;
  justify-items: center;
  display: flex;
}

.custom-dropdown {
  width: 350px !important;
}
.search-widget-form-item.passengers .ant-form-item-control .ant-form-item-control-input {
  width: 100%;
  height: 100%;
  text-align: left;
}
.search-widget-form-item.passengers .ant-form-item-control .ant-form-item-control-input-content {
  height: 100%;
  padding: 4px 11px 4px;
}
.search-widget-form-item.passengers .ant-form-item-control .ant-form-item-control-input-content a {
  display: flex;
  width: 100%;
  height: 100%;
  color: black;
  justify-content: flex-start;
  align-items: center;
}

.search-widget-form-item label {
  /* color: #fff !important; */
  color: black !important;
  font-weight: 500;
}

.search-widget-form-item .form-item .ant-select-selector,
.search-widget-form-item .form-item 
  {
  /* border: 0px !important; */
  /* border-radius: 0 !important; */
  border-radius: 8px;
  width: 100%;
}

.search-widget-form-item {
  margin-bottom: 0px !important;
}


.search-widget-form-item .form-item-first .ant-select-selector  {
  /* border-top-left-radius: 12px !important;
  border-bottom-left-radius: 12px !important; */
  border-radius: 6px !important;
}

.form-select-ticket {
  /* margin-top: 40px; */
  margin-top: 10px;
  background-color: transparent;
  /* padding: 20px; */
  border-radius: 8px;
  /*box-shadow: rgba(0, 0, 0, 0.12)0px 8px 24px 0px;*/
  /* width: 'auto'; */
  /* max-width: 1150px; */
  /* margin: 20px auto; */
  /* margin-left: 50px; */
  /* margin-right: 50px; */
  /*position: absolute;
  top: 25%;
  left: 15%;*/
  font-family: "Poppins", sans-serif;
  user-select: none;
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px; */
}

.main-form {
  display: flex;
  flex-direction: column;
  flex-flow: row wrap;
}

.form-group-select-ticket {
  margin-bottom: 10px;
}

.yatContainer {
  position: relative;
  width: 100%;
  height: 40px;
  /*height: 70px;*/
}

.one-way-two-way-container {
  display: flex;
  user-select: none;
}

.form-group-select-ticket .form-btn {
  margin-top: 44px;
  margin-left: 15px;
  background-color: transparent;
  border: 1px solid #dad8d8;
  font-size: 15px;
  color: #000;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
}

#from.select-way,#to.select-way {
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
}

.form-group-select-ticket .form-btn:hover {
  transition: 0.5s;
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
    rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
}

.form-group-select-ticket .form-btn:focus {
  border: 1px solid #343434;
}

.date-container {
  display: flex;
}

.form-group-select-ticket .CheckBoxForm {
  background-color: #114af3;
}

.departure-date {
  font-family: 'Poppins', sans-serif;
}

.form-select-ticket .search-form {
  /* margin-inline: 150px !important; */
  background-color: white;
  padding: 20px;
  border-radius: 8px;
}

@media screen and (max-width: 1125px) {
  .form-select-ticket .search-form {
    /* margin-left: 100px !important; */
    /* margin-right: 100px !important; */
  }
}
@media screen and (max-width: 1030px) {
  .form-select-ticket .search-form {
    /* margin-left: 75px !important; */
    /* margin-right: 75px !important; */
  }
}
@media screen and (max-width: 975px) {
  .form-select-ticket .search-form {
    /* margin-left: 50px !important; */
    /* margin-right: 50px !important; */
  }
}
@media screen and (max-width: 930px) {
  .form-select-ticket .search-form {
    /* margin-left: 25px !important; */
    /* margin-right: 25px !important; */
  }
}
@media screen and (max-width: 880px) {
  .form-select-ticket .search-form {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .form-select-ticket {
    /* margin-left: 10px !important;
    margin-right: 10px !important; */
  }
}
@media screen and (max-width: 600px) {
  .form-select-ticket .search-form {
    /* margin-left: 25px !important;
    margin-right: 25px !important; */
  }
  .departure-date {
    width: 100%;
    border-radius: 6px !important;
  }
  .return-date {
    width: 100%;
    min-width: 100%;
    border-radius: 6px !important;
  }
  .search-widget-form-item {
    width: 100% !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-right: 0px !important;
  }
  .search-widget-form-item label {
    height: 0px !important
  }
  .search-widget-form-container {
    display: block !important;
  }
  .tour-items {
    /* padding-left: 0px !important;
    padding-right: 0px !important; */
    width: 100%;
  }
}

.tour-items {
  width: 100%;
}

.return-date {
  border-top-left-radius: 0px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 10px;
  /* min-width: 130px; */
  font-family: 'Poppins', sans-serif;
}

label {
  text-align: left;
  padding-top: 0;
  margin-bottom: 5px;
}

input:focus,
input:hover,
textarea:focus,
textarea:hover,
select:focus,
select:hover {
  outline: none !important;
}

.select-way {
  /* width: 150px; */
  /* margin-right: 10px; */
  /* margin-bottom: 0px; */
  font-weight: 500;
  cursor: pointer;
  color: #383838;
}

.select-way option:checked {
  background-color: #e0e0e0;
}

input,
select,
.form-btn, .ticket-search-btn {
  width: 100%;
  padding: 10px;
  height: 56px;
  box-sizing: border-box;
  /*border: 1px solid #637489;*/
  border: 1px solid #cdcfd3;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
  background-color: white;
  /*box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;*/
}

.toggleContainer {
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: fit-content;
  border: 1px solid #dad8d8;
  border-radius: 5px;
  background: #e3e6ec;
  font-weight: bold;
  color: #343434;
  cursor: pointer;
  padding: 3px 3px 3px 3px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.toggleContainer::before {
  content: "";
  position: absolute;
  width: 50%;
  height: 100%;
  left: 0%;
  border-radius: 5px;
  background: #fff;
  transition: all 0.3s;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.toggleCheckbox:checked + .toggleContainer::before {
  left: 50%;
}
.toggleContainer div {
  padding: 5px;
  text-align: center;
  z-index: 1;
}
.toggleCheckbox {
  display: none;
}
.toggleCheckbox:checked + .toggleContainer div:first-child {
  color: #808080;
  transition: color 0.3s;
}
.toggleCheckbox:checked + .toggleContainer div:last-child {
  color: #090f24;
  transition: color 0.3s;
}
.toggleCheckbox + .toggleContainer div:first-child {
  color: #090f24;
  transition: color 0.3s;
}
.toggleCheckbox + .toggleContainer div:last-child {
  color: #808080;
  transition: color 0.3s;
}

.passenger-section {
  display: none;
}

.passenger-controls {
  display: flex;
  justify-content: space-between;
  width: 30%;
  margin-bottom: 10px;
}

.passenger-controls button {
  width: 30px;
  cursor: pointer;
  background-color: #cee1ff;
  color: #114af3;
  border: none;
  border-radius: 5px;
}

.passenger-controls button:hover {
  transition: 0.5s;
  background-color: #114af3;
  color: #fff;
}

.ticket-search-btn {
  background-color: #114af3;
  border: none;
  color: #fff;
  width: 100%;
  height: 56px;
  display: block;
  margin-left: auto;
  font-weight: 500;
  margin-right: auto;
  /* margin-top: 40px; */
  cursor: pointer;
  /* border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-top-right-radius: 12px !important;
  border-bottom-right-radius: 12px !important; */
  border-radius: 6px !important;
}

.ticket-search-btn:hover {
  background-color: #fff !important;
  color: black !important;
  transition: 0.5s;
  box-shadow: 0 8px 24px 0 rgba(16, 39, 112, 0.2);
}

.ticket-search-btn p {
  text-align: center;
  font-size: 18px;
  margin-top: 7px;
  font-family: "Poppins", sans-serif;
  color: #fff;
}

.passenger-input {
  min-width: 525px;
  margin-left: 10px;
  margin-right: 10px;
}

.modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 1000000;
}

.modal-okey-btn {
  background-color: #114af3;
  color: #fff;
  border: none;
  cursor: pointer;
}

.modal-okey-btn:hover {
  background-color: #054ab9;
  transition: 0.5s;
  box-shadow: 0 8px 24px 0 rgba(16, 39, 112, 0.2);
}

.modal.open {
  display: flex;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 70%;
  max-width: 600px;
}

@media (max-width: 767px) {
  .main-form {
    display: flex;
    flex-direction: column;
  }
  .select-way {
    width: 100%;
  }
  .ticket-search-btn {
    /* margin-top: 10px; */
    border-radius: 50px;
  }
  .form-select-ticket {
    top: 12%;
    /* width: 100%; */
    /* left: 5%; */
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
  }
  .form-group-select-ticket .form-btn {
    margin-left: 0px;
    margin-top: 0px;
  }

  .passenger-input {
    margin-left: 20px;
    margin-right: 20px;
    min-width: 200px;
    font-size: 16px;
    border: 1px solid #929699;
  }
  .label-container {
    display: none;
  }
  .yatContainer {
    height: 65px;
  }
  .one-way-two-way-container label {
    margin-left: 5px;
  }
  .date-container {
    min-width: 100%;
    margin-top: 15px;
  }
}

@media (max-width: 1466px) {
  .form-select-ticket {
    /* width: 100%; */
  }
}

.formSelectLabel {
  font-weight: 500;
  padding-bottom: 8px;
}
.formSelectInputForMobile {
  border-radius: 6px;
}

.custom-form-item {
  margin-bottom: 0;
}

.custom-select-container {
  position: relative;
}

.custom-select {
  width: 100%;
}

.custom-label {
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
  background: white;
  padding: 0 4px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  pointer-events: none;
  z-index: 1;
}