.index-banner {
  position: relative;
  justify-content: space-evenly;
  align-items: center;
  padding: 60px 0px;
  top: 10px;
  height: 80px;
  max-width: 100%;
  margin: 0 auto;
  background-color: #06213d;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  width: 95%;
  border-radius: 10px;
}

.index-banner h1 {
  font-family: "Poppins", sans-serif;
  margin-top: 0px;
  margin-bottom: 0px;
  line-height: 0px;
  position: absolute;
  text-align: left;
  font-size: 38px;
  margin-left: 218px;
  color: #fff;
}

@media (max-width: 767px) {
  .index-banner {
    height: 45px;
  }
  .index-banner h1 {
    text-align: center;
    font-size: 24px;
    margin-left: 20px;
  }
}


.container-admin {
  display: flex;
  margin-top: 60px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.card-info {
  width: 300px;
  margin: 20px auto;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-left: auto;
  margin-right: auto;
}

.card-info-text-container {
  display: flex;
  justify-content: space-between;
}

.card-info p {
  display: block;
  width: auto;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  margin-bottom: 10px;
}
.card-info-icon {
  padding: 5px;
  background-color: #DFDBFE;
  color: #928DFE;
  border-radius: 10px;
  margin-top: 10px;
  font-size: 26px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}
.card-info span {
  font-weight: 400;
}

.card-info h4 {
  margin-top: 10px;
  margin-bottom: 0px;
  font-size: 30px;
  font-family: "Poppins", sans-serif;
}

@media (min-width: 767px) and (max-width: 1800px) {
  .tours-banner h1 {
    text-align: center;
    margin-left: 40px;
  }
  .container-admin {
    width: 90%;
  }
  .card-info {
    margin-right: 20px;
  }
  .card-info p {
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .container-admin {
    width: 95%;
    margin-left: 10px;
    overflow-x: auto;
  }
  .card-info {
    margin-right: 10px;
    min-width: 200px;
  }
}

.index-banner-text {
  flex: 1;
}

.banner-img-admin {
  display: flex;
  justify-content: end;
  height: 50px;
  background-color: red;
}


.statsSpacer, 
.statsSpacer :global(.ant-space-item) {
        width: 100%;
}

.statsSpacer .ant-space-item {
  width: 100% !important;
}