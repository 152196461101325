.logo {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    padding: 10px;
}

.logo-icon {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    border-radius: 50%;
    background: rgba(28, 17, 41, 0.88);
}

.menu-bar {
    height: 88vh;
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 15px;
    font-size: 1.0rem;
    position: relative;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    user-select: none;
}

.toggle-theme-btn {
    position: absolute;
    margin-left: 20px;
    bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
}

.toggle {
    margin-left: 15px;
}

.ant-layout-header {
    width: 100%;
    background-color: red;
}