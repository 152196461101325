:root {
    --padding-desktop: 125px;
    --padding-mobile: 20px;
    --padding-1125: 100px;
    --padding-1030: 75px;
    --padding-975: 50px;
    --padding-930: 25px;
    --padding-880: 25px;
  }
  
  .main-container {
    padding-inline: var(--padding-desktop);
  }
  
  @media (max-width: 1125px) {
    .main-container {
      padding-inline: var(--padding-1125);
    }
  }
  @media (max-width: 1030px) {
    .main-container {
      padding-inline: var(--padding-1030);
    }
  }
  @media (max-width: 975px) {
    .main-container {
      padding-inline: var(--padding-975);
    }
  }
  @media (max-width: 930px) {
    .main-container {
      padding-inline: var(--padding-930);
    }
  }
  @media (max-width: 880px) {
    .main-container {
      padding-inline: var(--padding-880);
    }
  }
  @media (max-width: 767px) {
    .main-container {
      padding-inline: var(--padding-mobile);
    }
  }  

body {
    font-family: 'Poppins';
}