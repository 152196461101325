.background-container {
  background-size: cover;
  background-color: #06213d;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.login-btn {
  width: 100% !important;
  background-color: #06213d !important;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
}

.login-container {
  width: 400px;
  margin: 100px auto;
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  font-family: "Poppins", sans-serif;
}

.login-container h2 {
  text-align: center;
  margin-bottom: 20px;
}

.input-group-login {
  margin-bottom: 15px;
}

.input-group-login label {
  display: block;
  margin-bottom: 5px;
}

.input-group-login input {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  cursor: text;
}

button[type="submit"] {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 60%;
  padding: 10px;
  border: none;
  background-color: #114af3;
  color: #fff;
  border-radius: 90px;
  cursor: pointer;
}

button[type="submit"]:hover {
  background-color: #0056b3;
}

@media (max-width: 767px) {
  .login-container {
    width: 80%;
  }
}
