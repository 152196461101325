.pnr-text-section {
    /* position: relative; */
    /* top: 200px; */
    display: block;
    /* padding: 0 15px; */
    max-width: 1280px;
    margin: 0 auto;
    margin-left: 125px;
    margin-right: 125px;
    margin-top: 100px;
}

@media (max-width: 767px) {
    .pnr-text-section {
        margin-left: 40px;
        margin-right: 40px;
        margin-top: 25px;
    }
}

.pnr-text-section h2 {
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    color: #090f24;
    padding-bottom: 10px;
}

.pnr-text-section p {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    color: #525765;
    padding-bottom: 25px;
}

.br-bosluk {
    content: "A" !important;
    display: block !important;
    margin-bottom: 1.5em !important;
}

@media (max-width: 767px) {
    .pnr-text-section {
        top: 400px;
    }
}