@import url("https://fonts.cdnfonts.com/css/codec-pro");

/* ::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
  background-color: transparent;
  padding: 5px !important;
}

::-webkit-scrollbar-thumb {
  background-color: #054ab9;
  border: none;
  border-radius: 3px !important;
} */

.contact-banner {
  width: 100%;
}

.contact-banner-text-container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.contact-banner h1 {
  margin-top: 0px;
  margin-bottom: 10px;
  font-weight: 500;
  color: #fff;
  text-align: left;
}
.contact-banner span {
  color: #fff;
  text-align: left;
}

.contact-banner-card-container {
  position: relative;
  display: flex;
  margin-top: 20px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.contact-banner-card {
  margin-right: 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px 12px 16px;
  background-color: #fff;
  border: 1px solid #e6e7f1;
  width: 300px;
  border-radius: 5px;
}
.contact-banner-card:hover {
  transition: 0.6s;
  transform: translateY(-4px);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 8px 10px -10px;
}

.contact-banner-card-icon-first {
  background-color: #f1f1f6;
  padding: 10px;
  border-radius: 50%;
  color: #114af3;
}

.contact-banner-card-icon-two {
  color: #000;
}

.contact-banner-card p {
  font-size: 12px;
  font-weight: 500;
  color: #000;
}
/* 
@media (min-width: 767px) and (max-width: 1600px) {
  .contact-banner-text-container {
    width: 80%;
  }
  .contact-banner-card-container {
    width: 80%;
  }
  .contact-banner-card {
    width: 80%;
  }
} */

.contact-banner-text-container span {
  color: #fff
}

@media (max-width: 767px) {
  .contact-banner {
    width: 100%;
    height: auto;
  }
  .contact-banner-text-container {
    margin-top: 0px;
    line-height: 20px;
    width: 90%;
    text-align: center;
  }
  .contact-banner-card-container {
    width: 90%;
    display: block;
    overflow-y: auto;
  }
  .contact-banner-card {
    width: 90%;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
  }
  .contact-banner .contact-text-cont {
    width: 20px;
    margin-right: 120px;
    background-color: red;
    font-size: 18px;
    margin-left: 30px;
  }
  .contact-banner .contact-text-cont h1 {
    font-size: 14px;
  }
}

.contact-banner .contact-text-cont {
  height: 75px;
  margin-left: 320px;
}

.contact-banner .contact-text-cont h1 {
  color: #fff;
  font-family: "Poppins", sans-serif;
  text-align: left;
  font-size: 32px;
  margin: 0px;
}
.contact-banner .contact-text-cont p {
  color: #fff;
  font-family: "Poppins", sans-serif;
  text-align: left;
  font-size: 16px;
}

.input-box {
  position: relative;
  height: 76px;
  max-width: 900px;
  width: 100%;
  background: #fff;
  margin: 0 20px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5%;
}

.input-box .button {
  position: absolute;
  top: 50%;
  width: 115px;
  transform: translateY(-50%);
}

.input-box input {
  height: 100%;
  width: 100%;
  outline: none;
  font-size: 18px;
  font-weight: 400;
  border: none;
  padding: 0 155px 0 65px;
  background-color: transparent;
}

.input-box input::placeholder {
  font-family: "Poppins", sans-serif;
}

.input-box input::selection {
  background-color: #d6f5ff;
}

.input-box .button {
  right: 20px;
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  border: none;
  padding: 12px 30px;
  border-radius: 10px;
  background-color: #114af3;
  cursor: pointer;
}

.input-box .button:hover {
  background-color: #054ab9;
  transition: 0.5s;
  box-shadow: 0 8px 24px 0 rgba(16, 39, 112, 0.2);
}

.input-box .button:active {
  transform: translateY(-50%) scale(0.98);
}

.SearchIcon {
  position: absolute;
  justify-content: center;
  font-size: 18px;
  left: 20px;
}

@media screen and (max-width: 767px) {
  .input-box {
    height: 55px;
    margin: 0 8px;
    width: 95%;
  }
  .input-box input {
    padding: 0 112px 0 50px;
  }
  .input-box .button {
    right: 12px;
    font-size: 14px;
    padding: 8px 18px;
    height: 40px;
    width: 80px;
    border-radius: 5px;
  }
  .banner-content h1 {
    font-size: 26px;
  }
}

/*
.form-container-enter-invoice {
  background-color: #fff;
  z-index: 100;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 1150px;
  margin: 20px auto;
  position: absolute;
  top: 25%;
  left: 15%;
  font-family: 'Poppins', sans-serif;
}

.main-form {
  display: flex;
  flex-direction: column;
  flex-flow: row wrap;
}

.enter-form-group-invoice {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
}

.yatContainer {
  position: relative;
  width: 100%;
  height: 70px;
}

.enter-form-group-invoice .form-btn {
  margin-top: 24px;
  margin-left: 15px;
  background-color: transparent;
  border: 1px solid #637489;
  color: #000;
  font-family: 'Poppins', sans-serif;
  cursor: pointer;
}

.enter-form-group-invoice .form-btn:hover {
  transition: 0.5s;
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
}

.yolcuSecBtn {
  border: none;
  background-color: #637489;
}

.label-container {
  padding-bottom: 5px;
}

.date-container {
  display: flex;
}

.departure-date {
  border-top-left-radius: 10px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 0px;
}

.return-date {
  border-top-left-radius: 0px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 10px;
}

.mesajKutusu {
  resize: none;
  height: 130px;
  width: 320px;
  border: 1px solid #ced4da;
  border-radius: 10px;
  padding: 10px;
  font-family: 'Poppins', sans-serif;
}

label {
  text-align: left;
  margin-bottom: 5px;
}

input:focus,
input:hover,
textarea:focus,
textarea:hover,
select:focus,
select:hover {
  outline: none !important;
  border: 0.1px solid #292929;
}

.select-way {
  width: 275px;
  margin-right: 10px;
  font-weight: 500;
  border-radius: 90px;
}

input,
select,
button {
  width: 100%;
  padding: 10px;
  height: 56px;
  box-sizing: border-box;
  margin-bottom: 10px;
  border: 1px solid #ced4da;
  border-radius: 8px;
  font-family: 'Poppins', sans-serif;
  cursor: pointer;
}

@media (max-width: 767px) {
  .main-form {
    display: flex;
    flex-direction: column;
  }

  .select-way {
    width: 100%;
  }

  .ticket-search-btn {
    border-radius: 50px;
  }

  .form-container-enter-invoice {
    top: 18%;
    width: 80%;
    left: 5%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
  }

  .enter-form-group-invoice .form-btn {
    margin-left: 0px;
    margin-top: 0px;
  }

  .react-tel-input {
    border-radius: 0px;
    width: 100%;
  }

  .mesajKutusu {
    width: 93%;
  }
}

@media (max-width: 1466px) {
  .form-container-enter-invoice {
    width: 80%;
  }
}*/
