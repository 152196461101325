.feature-card {
  background-color: #f7f9fa;
  border-radius: 15px;
  padding: 20px;
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.feature-card .title {
  font-size: 16px;
  text-align: center;
  font-weight: 600;
  margin:0;
  color: #114af3;
}
.feature-card .description {
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  color: #828daf;
  text-align: center;
  margin: 0;
  margin-top: 16px;
}
.hero-section {
    position: relative;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 0 125px;
    max-width: 100%;
    margin: 0 auto;
    background-color: #fff;
  }
  
  .section-heading {
    color: #2B3991;
    font-family: 'Poppins', sans-serif;
    font-size: 30px;
    font-weight: 600;
    text-align: left;
  }
  
  .heading::selection {
    background-color: #D6F5FF;
  }
  
  .altBaslik {
    font-size: 18px;
    color: #828daf;
    font-family: 'Poppins', sans-serif;
    font-weight: normal;
    text-align: left;
    display: block;
    margin:0;
  }
  
  .altBaslik::selection {
    background-color: #D6F5FF;
  }
  
  @media (max-width: 767px) {
    .hero-section {
      padding-inline: 40px;
    }
    .section-heading {
      font-size: 16px;
      margin-bottom: 5px;
    }
  
    .altBaslik {
      font-size: 16px;
    }
  }
  
  main {
    display: grid;
    grid-template-columns: 1fr repeat(12, minmax(auto, 60px)) 1fr;
    grid-gap: 40px;
    padding: 60px 0;
    background-color: #fff;
  }
  
  .text--medium {
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    color: #969696;
    text-align: center;
    font-family: 'Poppins', sans-serif;
  }
  
  .text--medium::selection {
    background-color: #D6F5FF;
  }
  
  .cards {
    grid-column: 2 / span 12;
    display: grid;
    grid-template-columns: repeat(12, minmax(auto, 60px));
    grid-gap: 40px;
    border-radius: 15px;
  }
  
  .card {
    grid-column-end: span 4;
    display: flex;
    flex-direction: column;
    background-color: #F7F9FA;
    cursor: pointer;
    transition: all 0.3s ease 0s;
    border-radius: 15px;
    justify-content: center;
  }
  
  .card:hover {
    transform: translateY(-7px);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;
  }
  
  .card__image-container {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    padding-top: 56.25%;
    overflow: hidden;
    position: relative;
    pointer-events: none;
  }
  
  .card__image-container img {
    width: 150px;
    height: 150px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
    user-select: none;
  }
  
  .card__content {
    padding: 20px;
    padding-top: 0px;
  }
  
  .card__title {
    margin-bottom: 20px;
    color: #4A4A4A;
    font-weight: 600;
    text-align: center;
    font-family: 'Poppins', sans-serif;
  }
  
  .card__info {
    display: flex;
    align-self: end;
    align-items: center;
  }
  
  @media only screen and (max-width: 1000px) {
    .card {
      grid-column-end: span 6;
    }
  }
  
  @media only screen and (max-width: 700px) {
    main {
      grid-gap: 20px;
    }
  
    .card {
      grid-column-end: span 12;
    }
  }
  
  @media only screen and (max-width: 500px) {
    main {
      grid-template-columns: 10px repeat(6, 1fr) 10px;
      grid-gap: 10px;
    }
  
    .cards {
      grid-column: 2 / span 6;
      grid-template-columns: repeat(6, 1fr);
      grid-gap: 20px;
    }
  
    .card {
      grid-column-end: span 6;
    }
  }