.searchToursContainer h2 {
margin:0 !important;
}

.tourLabel {
    font-weight: 500;
    margin-right: 5px;
}
.tourLabelIcon {
    margin-right: 5px;
}
.tourLabelIconRight {
    margin-left: 5px;
}
.continue-container {
    position: sticky;
    bottom: 0;
    z-index: 9999999 !important;
    background-color: white;
    padding: 25px;
    -webkit-box-shadow: 0px -1px 8px 3px rgb(193 193 193 / 13%);
    -moz-box-shadow: 0px -1px 8px 3px rgb(193 193 193 / 13%);
    box-shadow: 0px -1px 8px 3px rgb(193 193 193 / 13%);
}

@media screen and (max-width: 600px) {
    .continue-container .continue-btn {
        margin-top: 20px;
        width: 100%;
    }
}

@media screen and (min-width: 1600px) {
    .searchToursContainer {
        min-height: 150vh;
    }
}
@media screen and (min-width: 2000px) {
    .searchToursContainer {
        min-height: 400vh;
    }
}