.tours-banner {
  position: relative;
  justify-content: space-evenly;
  align-items: center;
  padding: 60px 0px;
  top: 10px;
  height: 80px;
  max-width: 100%;
  margin: 0 auto;
  background-color: #06213d;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  width: 95%;
  border-radius: 10px;
}

.tours-banner h1 {
  font-family: "Poppins", sans-serif;
  margin-top: 0px;
  margin-bottom: 0px;
  line-height: 0px;
  position: absolute;
  text-align: left;
  font-size: 38px;
  margin-left: 218px;
  color: #fff;
}

@media (max-width: 767px) {
  .tours-banner {
    height: 45px;
  }
  .tours-banner h1 {
    text-align: center;
    font-size: 24px;
    margin-left: 20px;
  }
}